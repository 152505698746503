import Vue from "vue";
import Vuex from "vuex";
import { Card, CardWithUsers, UserWithCards } from "@/interfaces";
import client from "@/services/ct.service";
import { AxiosResponse } from "axios";

Vue.use(Vuex);

interface State {
  auth: {
    token: string | null;
    user?: UserWithCards;
  };
  cards: {
    [id: string]: Card;
  };
}

export default new Vuex.Store<State>({
  state: {
    auth: {
      token: localStorage.getItem("ct_token"),
      user: undefined,
    },
    cards: {},
  },
  getters: {
    getCard: (state) => (id: string) => state.cards[id],
  },
  mutations: {
    reset(state) {
      localStorage.removeItem("ct_token");
      state.auth = {
        token: null,
        user: undefined,
      };
      state.cards = {};
    },
    setToken(state, token) {
      state.auth.token = token;
      if (token) {
        localStorage.setItem("ct_token", token);
      } else {
        localStorage.removeItem("ct_token");
      }
    },
    setUser(state, user: UserWithCards) {
      state.auth.user = user;
      user.cards.forEach((card) => {
        state.cards = {
          ...state.cards,
          [card.id]: card,
        };
      });
    },
    setCard(state, card: CardWithUsers) {
      state.cards = {
        ...state.cards,
        [card.id]: card,
      };
    },
  },
  actions: {
    login({ commit, dispatch }, payload: { email: string; password: string }) {
      return new Promise((resolve, reject) => {
        client
          .post("/auth/login", payload)
          .then((res: AxiosResponse<{ token: string }>) => {
            commit("setToken", res.data.token);
            return dispatch("fetchMe");
          })
          .catch(reject);
      });
    },
    logout({ commit }) {
      commit("reset");
    },
    fetchMe({ commit }) {
      return new Promise((resolve, reject) => {
        client
          .get("/me")
          .then((res: AxiosResponse<UserWithCards>) => {
            commit("setUser", res.data);
            resolve(res.data);
          })
          .catch(reject);
      });
    },
    fetchCard({ commit }, id: string) {
      return new Promise((resolve, reject) => {
        client
          .get("/card/" + id)
          .then((res: AxiosResponse<CardWithUsers>) => {
            commit("setCard", res.data);
            resolve(res.data);
          })
          .catch(reject);
      });
    },
  },
});
