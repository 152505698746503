





























































import { ValidationObserver, ValidationProvider } from "vee-validate";
import { Component, Vue } from "vue-property-decorator";
import { Action, State } from "vuex-class";
import { User } from "@/interfaces";
import Spinner from "@/components/Spinner.vue";
import client from "@/services/ct.service";
import Icon from "@/components/Icon.vue";

@Component({
  components: {
    Spinner,
    Icon,
    ValidationObserver,
    ValidationProvider,
  },
})
export default class Login extends Vue {
  @State((state) => state.auth.token) token!: string | null;
  @Action fetchMe!: () => Promise<User>;
  @Action login!: (payload: {
    email: string;
    password: string;
  }) => Promise<User>;
  @Action logout!: () => void;

  isLoading = false;

  name = "";
  email = "";
  password = "";

  submit() {
    this.isLoading = true;

    client
      .post("/auth/register", {
        name: this.name,
        email: this.email,
        password: this.password,
      })
      .then(() => {
        // Notify and return
        this.$notify({
          type: "success",
          text: "Conta criada com sucesso. Pode usar agora os seus dados para entrar.",
        });
        this.$emit("close");
      })
      .catch((err) => {
        this.isLoading = false;
        this.$notify({
          type: "error",
          text: "Ocorreu um erro",
        });
      });
  }
}
