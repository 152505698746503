























import { Component, Emit, Prop, Vue } from "vue-property-decorator";
import Icon from "@/components/Icon.vue";
@Component({
  components: { Icon },
})
export default class Header extends Vue {
  @Prop(String) readonly icon!: string | null;
  @Prop(Boolean) readonly absolute!: boolean;
  @Prop(Boolean) readonly hideBack!: boolean;

  @Emit()
  back() {
    // Just emit
  }
}
