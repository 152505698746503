























import { Component, Vue } from "vue-property-decorator";
import { State } from "vuex-class";
import { UserWithCards } from "@/interfaces";
import Login from "@/views/Login.vue";
import Main from "@/views/Main.vue";
import Button from "@/components/Button.vue";
import AddCard from "@/views/AddCard.vue";
import Register from "@/views/Register.vue";

@Component({
  components: {
    Register,
    AddCard,
    Button,
    Main,
    Login,
  },
})
export default class App extends Vue {
  @State((state) => state.auth.user) user!: UserWithCards | undefined;

  refreshing = false;
  registration: ServiceWorkerRegistration | null = null;
  updateExists = false;

  isRegistration = false;

  showRefreshUI(e: any): void {
    this.registration = e.detail;
    this.updateExists = true;
  }

  refreshApp(): void {
    this.updateExists = false;
    if (!this.registration || !this.registration.waiting) {
      return;
    }
    this.registration.waiting.postMessage({ type: "SKIP_WAITING" });
  }

  get showLogin(): boolean {
    return !this.user;
  }

  created(): void {
    document.addEventListener("swUpdated", this.showRefreshUI, {
      once: true,
    });
    navigator.serviceWorker.addEventListener("controllerchange", () => {
      if (this.refreshing) return;
      this.refreshing = true;
      window.location.reload();
    });
  }
}
