













































































import { Component, Emit, Prop, Vue } from "vue-property-decorator";
import Icon from "@/components/Icon.vue";
import Button from "@/components/Button.vue";
import { Card } from "@/interfaces";
import { Page } from "@/views/Main.vue";
import SessionInfo from "@/components/SessionInfo.vue";
@Component({
  components: { SessionInfo, Button, Icon },
})
export default class Navigation extends Vue {
  @Prop(Object) readonly card!: Card;
  @Prop(String) readonly activePage!: Page | null;

  get isNfcSupported() {
    return "NDEFReader" in window;
  }

  @Emit()
  openPage(page: Page) {
    return page;
  }

  @Emit()
  closePage() {
    // Just emit
  }
}
