














import { Component, Emit, Prop, Vue } from "vue-property-decorator";
import { Card, CardWithUsers } from "@/interfaces";
import IconButton from "@/components/IconButton.vue";
import { Action } from "vuex-class";
import Icon from "@/components/Icon.vue";
@Component({
  components: { Icon, IconButton },
})
export default class PointsBar extends Vue {
  @Prop(Object) readonly card!: Card;

  @Action fetchCard!: (id: string) => Promise<CardWithUsers>;

  refreshing = false;

  @Emit()
  refresh() {
    this.refreshing = true;
    this.fetchCard(this.card.id).then(() => (this.refreshing = false));
  }
}
