import { extend, localize } from "vee-validate";
import { required, email, integer, min_value } from "vee-validate/dist/rules";
import pt from "vee-validate/dist/locale/pt_PT.json";

extend("email", email);
extend("required", required);
extend("integer", integer);
extend("min_value", min_value);

localize("pt_PT", pt);
