


















import { Component, Emit, Prop, Vue } from "vue-property-decorator";
import Button from "@/components/Button.vue";
import Spinner from "@/components/Spinner.vue";

@Component({
  components: { Spinner, Button },
})
export default class Modal extends Vue {
  @Prop(Boolean) readonly isOpen!: boolean;

  @Emit()
  close() {
    // Just emit
  }
}
