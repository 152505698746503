var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"screen"},[(!_vm.isLoading)?_c('button',{staticClass:"absolute left-4 top-4",on:{"click":function($event){return _vm.$emit('close')}}},[_c('Icon',{staticClass:"fill-current h-8 w-8 text-primary-200 hover:text-white focus:outline-none",attrs:{"name":"arrow-back"}})],1):_vm._e(),_c('div',{staticClass:"content"},[_c('img',{staticClass:"icon",attrs:{"src":require("../assets/icon.svg"),"alt":"Acólitos Card"}}),_c('h1',{staticClass:"text-white text-2xl font-bold text-center mb-6"},[_vm._v(" Criar conta ")]),_c('validation-observer',{attrs:{"slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('form',{staticClass:"form",attrs:{"novalidate":""},on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.submit)}}},[_c('validation-provider',{attrs:{"slim":"","name":"Nome"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"input"},[_c('label',{attrs:{"for":"name"}},[_vm._v("Nome")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.name),expression:"name"}],attrs:{"type":"text","id":"name","required":""},domProps:{"value":(_vm.name)},on:{"input":function($event){if($event.target.composing){ return; }_vm.name=$event.target.value}}}),(errors[0])?_c('p',{staticClass:"error"},[_vm._v(_vm._s(errors[0]))]):_vm._e()])]}}],null,true)}),_c('validation-provider',{attrs:{"slim":"","name":"Endereço de e-mail"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"input"},[_c('label',{attrs:{"for":"email"}},[_vm._v("Endereço de email")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.email),expression:"email"}],attrs:{"type":"email","id":"email","required":""},domProps:{"value":(_vm.email)},on:{"input":function($event){if($event.target.composing){ return; }_vm.email=$event.target.value}}}),(errors[0])?_c('p',{staticClass:"error"},[_vm._v(_vm._s(errors[0]))]):_vm._e()])]}}],null,true)}),_c('validation-provider',{attrs:{"slim":"","name":"Password"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('div',{staticClass:"input"},[_c('label',{attrs:{"for":"password"}},[_vm._v("Password")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.password),expression:"password"}],attrs:{"type":"password","id":"password","required":""},domProps:{"value":(_vm.password)},on:{"input":function($event){if($event.target.composing){ return; }_vm.password=$event.target.value}}}),(errors[0])?_c('p',{staticClass:"error"},[_vm._v(_vm._s(errors[0]))]):_vm._e()])]}}],null,true)}),_c('button',{staticClass:"button",attrs:{"type":"submit"}},[_vm._v("Criar conta")]),_c('div',{staticClass:"loader",class:{ active: _vm.isLoading }},[_c('Spinner',{staticClass:"text-white"})],1)],1)]}}])})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }