import axios from "axios";

const client = axios.create({
  baseURL: process.env.VUE_APP_API_URL,
});

client.interceptors.request.use((reqConfig) => {
  const token = localStorage.getItem("ct_token");
  if (token) {
    reqConfig.headers = {
      ...reqConfig.headers,
      Authorization: `Bearer ${token}`,
    };
  }
  return reqConfig;
}, Promise.reject);

export default client;
