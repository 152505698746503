







import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import { CatalogItem } from "@/interfaces";
import Icon from "@/components/Icon.vue";
import client from "@/services/ct.service";
@Component({
  components: { Icon },
})
export default class CatalogItemIcon extends Vue {
  @Prop(Object) readonly item!: CatalogItem;

  imageSrc: string | null = null;

  @Watch("item")
  loadImage() {
    if (this.item.image) {
      client
        .get(this.item.image, {
          responseType: "arraybuffer",
        })
        .then((res) => {
          const image = btoa(
            new Uint8Array(res.data).reduce(
              (data, byte) => data + String.fromCharCode(byte),
              ""
            )
          );
          this.imageSrc = `data:${res.headers[
            "content-type"
          ].toLowerCase()};base64,${image}`;
        })
        .catch((err) => {
          this.imageSrc = null;
        });
    }
  }

  mounted() {
    this.loadImage();
  }
}
