









import { Component, Emit, Prop, Vue } from "vue-property-decorator";

@Component
export default class Input extends Vue {
  @Prop(String) readonly name!: string;
  @Prop({ type: String, default: "text" }) readonly type!: string;
  @Prop(String) readonly label!: string;
  @Prop([String, Number]) readonly value!: string | number;
  @Prop(String) readonly hint!: string | null;
  @Prop(String) readonly error!: string | null;

  @Emit("input")
  onInput(event: Event) {
    return (event.target as HTMLInputElement).value;
  }
}
