




































import { Component, Emit, Prop, Vue, Watch } from "vue-property-decorator";
import { Card, CatalogItem } from "@/interfaces";
import Icon from "@/components/Icon.vue";
import Header from "@/components/Header.vue";
import Spinner from "@/components/Spinner.vue";
import client from "@/services/ct.service";
import { AxiosResponse } from "axios";
import CatalogItemModal from "@/components/CatalogItemModal.vue";
import Page from "@/components/Page.vue";
import CatalogItemIcon from "@/components/CatalogItemIcon.vue";
@Component({
  components: {
    CatalogItemIcon,
    Page,
    CatalogItemModal,
    Spinner,
    Header,
    Icon,
  },
})
export default class Catalog extends Vue {
  @Prop(Object) readonly card!: Card;
  @Prop(Boolean) readonly isOpen!: boolean;

  catalogItems: CatalogItem[] = [];
  isLoading = false;
  openItem: CatalogItem | null = null;

  fetch() {
    this.isLoading = true;
    client
      .get(`/card/${this.card.id}/catalog`)
      .then((res: AxiosResponse<CatalogItem[]>) => {
        this.catalogItems = res.data;
        this.isLoading = false;
      });
  }

  @Watch("isOpen")
  onIsOpenChange(newVal: boolean) {
    if (newVal) this.fetch();
  }

  @Emit()
  back() {
    // Just emit
  }

  mounted() {
    if (this.isOpen) this.fetch();
  }
}
