






import { Component, Emit, Prop, Vue } from "vue-property-decorator";

@Component
export default class IconButton extends Vue {
  @Prop({ type: Boolean, default: false }) readonly primary!: boolean;
  @Prop({ type: Boolean, default: false }) readonly disabled!: boolean;

  @Emit()
  click() {
    // Just emit
  }
}
