import { Component, Vue } from "vue-property-decorator";
import { DateTime } from "luxon";

@Component
export class Formatters extends Vue {
  dateFormat(isoDate: string, format = DateTime.DATETIME_MED) {
    const date = DateTime.fromISO(isoDate);
    return date.toLocaleString(format);
  }
}
