
































































import { Component, Vue } from "vue-property-decorator";
import { Action, State } from "vuex-class";
import { Card, CardWithUsers } from "@/interfaces";
import IconButton from "@/components/IconButton.vue";
import PointsBar from "@/components/PointsBar.vue";
import History from "@/components/History.vue";
import Button from "@/components/Button.vue";
import Catalog from "@/views/Catalog.vue";
import Icon from "@/components/Icon.vue";
import Scanner from "@/views/Scanner.vue";
import AddPoints from "@/views/AddPoints.vue";
import client from "@/services/ct.service";
import Contactless from "@/views/Contactless.vue";
import CardInfo from "@/components/CardInfo.vue";
import Navigation from "@/components/Navigation.vue";
import SessionInfo from "@/components/SessionInfo.vue";
import ChangeCard from "@/views/ChangeCard.vue";
import AddCard from "@/views/AddCard.vue";

export type Page =
  | "catalog"
  | "scan"
  | "add-points"
  | "contactless"
  | "change-card"
  | "add-card";

@Component({
  components: {
    AddCard,
    ChangeCard,
    SessionInfo,
    Navigation,
    CardInfo,
    Contactless,
    AddPoints,
    Scanner,
    Icon,
    Catalog,
    Button,
    History,
    PointsBar,
    IconButton,
  },
})
export default class Main extends Vue {
  @State((state) => state.cards) cards!: { [id: string]: Card };
  @Action fetchCard!: (id: string) => Promise<CardWithUsers>;

  activeCardId: string | null = null;

  openPageId: Page | null = null;

  get activeCard(): Card | null {
    if (!this.activeCardId) return null;
    return this.cards[this.activeCardId];
  }

  get isNfcSupported(): boolean {
    return "NDEFReader" in window;
  }

  changeActiveCard(card: Card): void {
    this.activeCardId = card.id;
    this.closePage();
  }

  onAddCardClose(card?: Card): void {
    if (card) {
      this.changeActiveCard(card);
    } else {
      this.closePage();
    }
  }

  openPage(page: Page): void {
    this.openPageId = page;
  }

  closePage(): void {
    this.$el.scrollTo(0, 0);
    this.openPageId = null;
    this.refresh();
  }

  refresh(): void {
    if (!this.activeCardId) return;
    this.fetchCard(this.activeCardId);
    this.$nextTick(() => {
      (this.$refs.history as History).refresh();
    });
  }

  urlB64ToUint8Array(base64String: string): Uint8Array {
    const padding = "=".repeat((4 - (base64String.length % 4)) % 4);
    const base64 = (base64String + padding)
      .replace(/-/g, "+")
      .replace(/_/g, "/");

    const rawData = window.atob(base64);
    const outputArray = new Uint8Array(rawData.length);

    for (let i = 0; i < rawData.length; ++i) {
      outputArray[i] = rawData.charCodeAt(i);
    }
    return outputArray;
  }

  created(): void {
    const ids = Object.keys(this.cards);
    if (ids.length > 0) this.activeCardId = ids[0];

    Notification.requestPermission().then((result) => {
      // Do nothing
    });

    navigator.serviceWorker
      .getRegistration()
      .then((registration) => {
        if (registration) {
          const options = {
            userVisibleOnly: true,
            applicationServerKey: this.urlB64ToUint8Array(
              "BNvfWLJvhfpErMhbv_J-qnQYQy1pGs8rzUtsTNVt9MMT_-9qaVAbKgElmfocrmFQtzqgnrMyj9pyiQ2QXUYzpZU"
            ),
          };

          registration.pushManager
            .subscribe(options)
            .then((sub) => {
              const key = sub.getKey("p256dh");
              const token = sub.getKey("auth");
              client
                .put("/subscription", {
                  endpoint: sub.endpoint,
                  key: key
                    ? btoa(
                        String.fromCharCode.apply(
                          null,
                          new Uint8Array(key) as unknown as number[]
                        )
                      )
                    : null,
                  token: token
                    ? btoa(
                        String.fromCharCode.apply(
                          null,
                          new Uint8Array(token) as unknown as number[]
                        )
                      )
                    : null,
                })
                .then((res) => {
                  // Do nothing
                })
                .catch((err) => console.log(err));
            })
            .catch((err) => console.log(err));
        }
      })
      .catch((err) => console.log(err));
  }
}
