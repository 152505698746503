












































import { Component, Emit, Prop, Vue } from "vue-property-decorator";
import { Card, CatalogItem } from "@/interfaces";
import Button from "@/components/Button.vue";
import Spinner from "@/components/Spinner.vue";
import client from "@/services/ct.service";
import Modal from "@/components/Modal.vue";
import CatalogItemIcon from "@/components/CatalogItemIcon.vue";

@Component({
  components: { CatalogItemIcon, Modal, Spinner, Button },
})
export default class CatalogItemModal extends Vue {
  @Prop(Object) readonly card!: Card;
  @Prop(Object) readonly item: CatalogItem | null = null;

  isLoading = false;

  order() {
    if (!this.item) return;
    this.isLoading = true;
    client
      .post(`/card/${this.card.id}/order`, { item: this.item.id })
      .then(() => {
        this.isLoading = false;
        this.close();
        this.$notify({
          type: "success",
          text: "Pontos trocados com sucesso.",
        });
      })
      .catch(() => {
        this.isLoading = false;
        this.$notify({
          type: "error",
          text: "Ocorreu um erro",
        });
      });
  }

  @Emit()
  close() {
    // Just emit
  }
}
