import Vue from "vue";
import Notifications from "vue-notification";
import { Settings } from "luxon";
import * as Sentry from "@sentry/vue";
import VueOffline from "vue-offline";

import App from "./App.vue";
import "./registerServiceWorker";
import store from "./store";
import "./validation";
import "./index.css";

Settings.defaultLocale = "pt";

Vue.config.productionTip = false;

Vue.use(Notifications);

Vue.use(VueOffline);

Sentry.init({
  Vue,
  dsn: "https://62f263ee39024d1c8f3cebc6fb1900a7@o493975.ingest.sentry.io/5564498",
  autoSessionTracking: true,
  logErrors: true,
});

new Vue({
  store,
  render: (h) => h(App),
}).$mount("#app");
