





























































import { Component, Emit, Prop, Vue, Watch } from "vue-property-decorator";
import { Card, User, UserWithCards } from "@/interfaces";
import { Action, State } from "vuex-class";
import Page from "@/components/Page.vue";
import Header from "@/components/Header.vue";
import client from "@/services/ct.service";
import { AxiosResponse } from "axios";
import { ValidationObserver, ValidationProvider } from "vee-validate";
import Spinner from "@/components/Spinner.vue";
import Button from "@/components/Button.vue";
import Input from "@/components/Input.vue";

interface AddCardPayload {
  id: string;
  code: string;
}

@Component({
  components: {
    Page,
    Spinner,
    Button,
    Input,
    Header,
    ValidationObserver,
    ValidationProvider,
  },
})
export default class AddCard extends Vue {
  @State((state) => state.auth.user) user!: UserWithCards | undefined;
  @Prop(Boolean) readonly isOpen!: boolean;

  @Action fetchMe!: () => Promise<User>;

  isLoading = false;

  card: AddCardPayload = {
    id: "",
    code: "",
  };

  submit(): void {
    this.isLoading = true;
    client
      .post(`/me/add-card`, this.card)
      .then((response: AxiosResponse<Card>) => {
        this.$notify({
          type: "success",
          text: "Cartão adicionado com sucesso.",
        });
        this.fetchMe();
        this.back(response.data);
      })
      .catch(() => {
        this.isLoading = false;
        this.$notify({
          type: "error",
          text: "Ocorreu um erro.",
        });
      });
  }

  reset(): void {
    this.card = {
      id: "",
      code: "",
    };
    this.isLoading = false;
  }

  @Watch("isOpen")
  onIsOpenChange(newVal: boolean): void {
    if (newVal) this.reset();
  }

  @Emit()
  back(card?: Card): Card | undefined {
    return card;
  }
}
